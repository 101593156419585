.wrapper {
  height: 100vh;
  display: flex;
  margin: 0 10%;
  justify-content: center;
  /* align-items: center; */
  width: 80vw;
  gap: 8rem;
  margin-top: 5rem;
}
.wrapper h3 {
  background: var(--text-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;
}
.circle {
  background-color: #e23764;
  height: 15rem;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.icon {
  font-size: 30rem;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  /* justify-content: center; */
}
.right span {
  line-height: 24px;
  text-transform: capitalize;
  text-align: center;
}
.btn {
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  border-radius: var(--ticket-border-radius);
  border: none;
  background-image: var(--btn-gradient-left);
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 810px) {
  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
